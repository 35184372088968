import {Dpa} from "./scDetail.interface";
import {Col, Row, Typography } from "antd";
import {useTranslation} from "react-i18next";

const { Text } = Typography;

function ScDpaList({ dpa }: { dpa: Dpa | null }) {

  const { t } = useTranslation();

  const getTranslation = (key: string): string => {
    const translations: any = {
      Region: 'scDetailRegion',
      Country: 'scDetailCountry',
      Pu: 'scDetailPu',
      'Level 4': 'scDetailLvl4',
      'Level 5': 'scDetailLvl5',
      'Level 6': 'scDetailLvl6',
      'Level 7': 'scDetailLvl7',
      'Level 8': 'scDetailLvl8',
    }
    return t(translations[key]);
  }
  return (
    <div className='w-100'>
      {
        (dpa?.dpaFather) ? (
          <Row>
            <ScDpaList dpa={dpa.dpaFather}/>
            <Col span={24}>
              <Row justify='space-between'>
                <Col>
                  {
                    getTranslation(dpa?.dpaType?.dtyName || '')
                  }
                </Col>
                <Col>
                  <Text strong>{ dpa?.dpaName }</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row className="w-100">
            <Col span={24} className="w-100">
              <Row justify='space-between'>
                <Col>
                  {
                    getTranslation(dpa?.dpaType?.dtyName || '')
                  }
                </Col>
                <Col>
                  <Text strong>{ dpa?.dpaName }</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }
    </div>
  );
}

export default ScDpaList;
