import {Col, Row, Typography} from "antd";
import {GlobalCard} from "../Components/GlobalCard";
import {useEffect, useState} from "react";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import useFetch from "../../hooks/useFetch";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import useMessageUser from "../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";

const { Title, Paragraph } = Typography;

function Profile() {

  const [auth, setAuth] = useState<any>({});
  const { fetchApi } = useFetch();
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();

  useEffect(() => {
    getUserInfo();
  }, []);


  const getUserInfo = () => {
    const dataSend: FetchApiInterface = {
      url: `/users/get_user`
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setAuth(dataResponse);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return (
    <Row justify='center' className='py-2'>
      <Col span={23}>
        <GlobalCard breadcrumb={[
          {
            children: `${t('myProfile')}`
          }
        ]}>
          <Title level={2}>{t('myProfileTitle')}</Title>
          <Title level={4}>{t('myProfileDescription')}</Title>

          <Title level={5}>{t('profileName')}</Title>
          <Paragraph>{ auth?.useName } { auth?.useLastName }</Paragraph>

          <Title level={5}>{t('profileEmail')}</Title>
          <Paragraph>{ auth?.useEmail }</Paragraph>

          <Title level={5}>{t('profilePu')}</Title>
          <Paragraph>{ auth?.dpa?.dpaName }</Paragraph>

          <Title level={5}>{t('profileRole')}</Title>
          {
            auth?.roles?.map((rol: any, index: number)  => (
              <Paragraph key={index}>{ rol?.rolLabel }</Paragraph>
            ))
          }


        </GlobalCard>
      </Col>
    </Row>
  );
}

export default Profile;
