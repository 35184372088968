import {useEffect, useState} from "react";

function CurrentLanguage() {
  const [language, setLanguage] = useState<any>({});

  useEffect(() => {
    if (language?.lanId) {
      localStorage.setItem('lng-selected', JSON.stringify(language));
    }
  }, [language]);

  return { language, setLanguage};
}

export default CurrentLanguage;
