import {Layout} from "antd";

const { Header, Footer, Sider, Content } = Layout;

const LayoutComms = ({ children }: { children: React.ReactNode }) => {
  return(
    <Layout>
      <Content>
        { children }
      </Content>
    </Layout>
  );
}

export default LayoutComms;
