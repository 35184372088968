import {useParams} from "react-router-dom";
import {
  Breadcrumb, Button,
  Col,
  Row,
  Typography,
} from "antd";
import FormGroup from "../../components/Users/FormGroup";
import ListGroups from "../../components/Users/ListGroups";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {getScopes} from "../../helperComms";

const { Title} = Typography;
const GroupsPage = () => {

  const { key } = useParams();
  const [view, setView] = useState<string>('list');
  const [data, setData] = useState<any>({});
  const { t } = useTranslation();
  const scopes = getScopes();

  const createGroup = () => {
    setData({});
    setView('form');
  }

  return(
    <Row justify='center'>
      <Col span={24} className='p-1'>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>{t('user')}</Breadcrumb.Item>
              <Breadcrumb.Item>{key}</Breadcrumb.Item>
            </Breadcrumb>
            <Title level={3}>{t('groupsOrProfiles')}</Title>
          </Col>
          <Col span={24}>
            {
              view === 'list' ? (
                <>
                  {
                    scopes.includes('USUARIOS_GROUP_NEW') && (
                      <Button shape='round' type='primary' className='mb-1' onClick={createGroup}>{t('createNewGroup')}</Button>
                    )
                  }
                  <ListGroups setView={setView} setData={setData} />
                </>
              ) : (
                <FormGroup setView={setView} dataEdit={data} />
              )
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default GroupsPage;
