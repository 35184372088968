import fetch from '../config/interceptor';
import {FetchApiInterface} from "../interfaces/FetchApi.interface";
import {useState} from "react";
import {AxiosResponse} from "axios";

function useFetch() {

  const [ loading, setLoading ] = useState(false);

  const fetchApi = ({url, data = {}, method = 'POST', formData = false}: FetchApiInterface) => {
    setLoading(true);
    const config = {
      url,
      method,
      data,
      formData
    };

    return fetch(config).finally(() => setLoading(false));
  }

  const downloadFile = (url: string, data = {}, method = 'post') => {
    // Primera solicitud intentando obtener un Blob
    return fetch({
      url,
      method,
      data,
      responseType: 'blob'
    })
      .then(response => {
        if (response.status === 200) {
          return {
            status: response.status,
            data: response.data,
          };
        } else {
          throw new Error('Not a successful response or not a zip file.');
        }
      })
      .catch(() => {
        // Segunda solicitud en caso de error para obtener el mensaje de error en formato JSON
        return fetch({
          url,
          method,
          data
          // responseType no se define, por defecto es 'json'
        })
          .then(response => {
            // Como sabemos que es un error, directamente rechazamos la promesa con el mensaje de error
            throw new Error(response.data.message || 'Error desconocido');
          });
      });
  }


  return { fetchApi, downloadFile, loading };

}

export default useFetch;
