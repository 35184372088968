import useFetch from "../../hooks/useFetch";
import {useContext, useEffect, useState} from "react";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import {Button, Form, message, Select, Spin, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {USER_INFO} from "../../config/config";
import GlobalContex from "../../Store/GlobalContext";
import useMessageUser from "../../hooks/useMessageUser";

const { Option } = Select;
const { Text } = Typography;

export interface IDpaSelected {
  form: any,
  setFilterApply: any,
  filterApply: any,
  maxLevel?: number | null,
  setEndLevel?: any,
  name?: string,
  isRequired?: boolean,
}

function DpaSelected({ form, setFilterApply, filterApply, maxLevel = null, setEndLevel = () => {}, name = 'dpa', isRequired = false }: IDpaSelected) {
  const { user: { auth } } = useContext(GlobalContex);

  const { fetchApi, loading } = useFetch();
  const { t } = useTranslation();
  const [dpaList, setDpaList] = useState([]);
  const [dpaIds, setDpaIds] = useState<Array<any>>([]);
  const [multiple, setMultiple] = useState<boolean>(false);
  const [currentLevel, setCurrentLevel] = useState<number>(0);
  const { showMessage }  = useMessageUser();

  useEffect(() => {
    if (Object.keys(filterApply).length <= 0) {
      setCurrentLevel(0);
    }
  }, [filterApply]);

  useEffect(() => {
    if (((Object.keys(filterApply).length <= 0) && currentLevel === 0) || dpaList.length <= 0) {
      setMultiple(false);
      setDpaIds([]);
      if(auth?.roles && !(auth?.roles?.includes('ROLE_SYSADMIN') || auth?.roles?.includes('ROLE_GLOBAL_ADMIN'))) {
        if (auth?.roles?.includes('ROLE_REGION_ADMIN')) {
          const dpaId = auth?.user?.dpa?.dpaIdRegion;
          getRegions(JSON.stringify({dpaId}));
          return;
        }
        if (auth?.roles?.includes('ROLE_COUNTRY_ADMIN')) {
          if (maxLevel == 2) {
            const dpaId = auth?.user?.dpa?.dpaIdRegion;
            getRegions(JSON.stringify({dpaId}));
          } else {
            const dpaId = auth?.user?.dpa?.dpaIdCountry;
            getRegions(JSON.stringify({dpaId}));
          }
        }
        if (auth?.roles.includes('ROLE_USER') || auth?.roles.includes('ROLE_GUEST') || auth?.roles?.includes('ROLE_COUNTRY_PROCESS')) {
          getCommunities();
        }
      } else {
        getRegions();
      }
    } else {
      console.log('No llama a nadie');
    }
  }, [currentLevel, auth]);

  const getRegions = (dataDpa?: any, addList = true) => {
    const dpa = dataDpa ? JSON.parse(dataDpa) : null;

    let data: any = {
      dpaType: "Region",
      statusRegister: true,
    }
    if (dpa?.dpaId) {
      data = {
        dpaFatherId: dpa.dpaId,
        statusRegister: true,
      }
      // Seteamos el ultimo dpi seleccionado
      let filters = {...filterApply};
      if (name) {
        filters[name] = dpa.dpaId;
      } else {
        filters['dpas'] = [
          {
            dpaId: dpa.dpaId,
          }
        ]
      }
      setFilterApply(filters);
    }

    // Verificamos que maximo pueda llegar hasta el nivel dado
    if (maxLevel && (currentLevel >= maxLevel)) {
      return;
    }

    const dataSend: FetchApiInterface = {
      url: `/catalogs/dpa/get_dpa`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataToList = data?.data || [];
          if (dataToList?.length) {
            setCurrentLevel(currentLevel + 1);
            form.setFieldValue('dpa', undefined);
            setDpaList(dataToList);
            if (dpa && addList && dpa?.dpaName) {
              const listDpas = [...dpaIds];
              listDpas.push(dpa);
              setDpaIds(listDpas);
            }
            if (
              maxLevel === 2 &&
              auth?.roles?.some((role: any) => role.rolName === 'ROLE_REGION_ADMIN' || role.rolName === 'ROLE_COUNTRY_ADMIN') &&
              !auth?.roles?.some((role: any) => role.rolName === 'ROLE_SYSADMIN' || role.rolName === 'ROLE_GLOBAL_ADMIN')
            ) {
              setCurrentLevel(2);
              setEndLevel(true);
            } else {
              setEndLevel(false);
            }
          } else {
            // Entra aca porque no hay mas niveles
            setMultiple(true);
            setEndLevel(true);
            const values = form.getFieldsValue();
            const value = values?.dpa;
            let dpaId = null;
            if (typeof value === 'string') {
              const dataValue = JSON.parse(value);
              dpaId = dataValue.dpaId;
            } else {
              dpaId = value?.dpaId;
            }
            if (dpaId) {
              form.setFieldValue('dpa', [dpaId]);
            }
          }
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const getCommunities = () => {
    const dataSend: FetchApiInterface = {
      url: `/users/get_communities`,
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataCommunities = data?.data || [];
          setDpaList(dataCommunities);
          if (auth?.roles?.includes('ROLE_COUNTRY_PROCESS')) {
            setMultiple(false);
          } else {
            setMultiple(true);
          }
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const applyFilterMultiple = (values: Array<number>) => {
    const listDpasIds: { dpaId: number; }[] = [];
    values.forEach(value => {
      let dpaId = value;
      if (typeof value === 'string') {
        const dataValue = JSON.parse(value);
        dpaId = dataValue.dpaId;
      }
      listDpasIds.push({
        dpaId: dpaId,
      });
    });
    if (form) {
      let dpaIdValues = listDpasIds.map(item => item.dpaId);
      form.setFieldValue('dpa', dpaIdValues);
    }
    // Seteamos el ultimo dpi seleccionado
    let filters = {...filterApply};
    filters['dpas'] = listDpasIds;
    setFilterApply(filters);
  }

  const backDpa = (dpa: any) => {
    setMultiple(false);
    form.setFieldValue('dpa', undefined);
    const dta: any[] = sliceDpaIds(dpaIds, dpa.dpaId);
    setDpaIds(dta);
    getRegions(JSON.stringify(dpa), false);
  }

  const sliceDpaIds = (dpaIds: any[], dpaId: number) => {
    const data: any[] = JSON.parse(JSON.stringify(dpaIds));
    const index = data.findIndex((obj) => obj.dpaId === dpaId);
    if (index !== -1) {
      return data.slice(0, index + 1);
    }
    return data;
  }

  const clearInput = () => {
    let filters = {...filterApply};
    delete filters?.dpas;
    setFilterApply(filters);
    form.setFieldValue('dpa', null);
    // setDpaList([]);
  }

  return (
    <div>
      <p>
        {
          dpaIds.map((dpa: any, index) => (
            <Button style={{ padding: 0 }} type='link' key={index} disabled={((dpaIds.length - 1) == index)} onClick={() => backDpa(dpa)}>
              <Text strong={((dpaIds.length - 1) == index)} style={{ color: `${((dpaIds.length - 1) == index) ? '#0072CE' : 'black'}` }}>
                /{ dpa?.dpaName }
              </Text>
            </Button>
          ))
        }
      </p>
      <Spin spinning={loading}>
        <Form.Item
          label={t('dpaLabelName')}
          name={name || 'dpa'}
          rules={[
            {
              required: (isRequired || !!(maxLevel)),
              message: t('codeRequired')
            }
          ]}
        >
          {
            (multiple) ? (
              <Select
                placeholder={t('dpaPlaceholderNameMultiple')}
                allowClear
                showSearch
                mode='multiple'
                onChange={ applyFilterMultiple }
              >
                {
                  dpaList.map((item: any) => (
                    <Option key={item.dpaId} value={item.dpaId}>{ item.dpaName }</Option>
                  ))
                }
              </Select>
            ) : (
              <Select
                placeholder={t('dpaPlaceholderNameSingle')}
                allowClear
                showSearch
                onChange={ (e) => getRegions(e) }
                onClear={clearInput}
              >
                {
                  dpaList.map((item: any) => (
                    <Option key={item.dpaId} value={JSON.stringify(item)}>{ item.dpaName }</Option>
                  ))
                }
              </Select>
            )
          }
        </Form.Item>
      </Spin>
    </div>
  );
}
export default DpaSelected;
