function useTransformFormData() {

  const transformFormData = (values: any) => {

    const formData = new FormData();

    Object.keys(values).forEach((index: string) => {
      if (values[index]) {
        formData.set(index, values[index]);
      }
    });

    return formData;
  }

  return { transformFormData };

}

export default useTransformFormData;
