import {useParams} from "react-router-dom";
import {
  Breadcrumb,
  Card,
  Col,
  Row,
  Typography,
} from "antd";
import TableCreateXMLSCU from "../../components/TableCreateXMLSCU";

const { Title, Paragraph, Text } = Typography;
const CreateXMLSCU = () => {

  const { key } = useParams();

  return(
    <Row justify='center'>
      <Col span={24} className='p-1'>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>SCUAPP</Breadcrumb.Item>
              <Breadcrumb.Item>{ key }</Breadcrumb.Item>
            </Breadcrumb>
            <Title level={3}>Detalle de SCU - 96 de 100 Exitosos</Title>
          </Col>
          <Col span={24}>
            <Card>
              <Row>
                <Col span={24}>
                  <TableCreateXMLSCU />
                </Col>
              </Row>
            </Card>
          </Col>
          {/*
          <Col span={24}>
            <Card>
              <Row>
                <Col span={24}>
                  <Paragraph>Agenda:</Paragraph>
                  <TextArea rows={4} placeholder="Pega aqui los números  de la agenda para generar el XML. Pega el listado para abajo" maxLength={6} />
                  <Button className='mt-1' type='primary' shape='round'>Filtrar Agenda</Button>
                </Col>
              </Row>
            </Card>
          </Col>*/}
          {/*<Col span={24}>
            <Card className='border-radius'>
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <Title level={5}>Detalle de SCU - 96 de 100 Exitosos</Title>
                </Col>
                <Col span={24}>
                  <Row justify='space-between'>
                    <Col>
                      <Button shape='round' type='primary' ghost icon={<FileExcelOutlined />} onClick={() => setOpenModal(true)}>Filtros <Text className='color-primary' strong>(3)</Text></Button>
                      <Modal width={800} title='Filtros' open={openModal} footer={
                        <Row justify='space-between'>
                          <Col>
                            <Button shape='round' type='primary' danger ghost onClick={() => setOpenModal(false)}>Limpiar Filtros</Button>
                          </Col>
                          <Col>
                            <Space>
                              <Button shape='round' type='text' onClick={() => setOpenModal(false)}>Cancelar</Button>
                              <Button shape='round' type='primary' onClick={() => setOpenModal(false)}>Aplicar Filtros</Button>
                            </Space>
                          </Col>
                        </Row>
                      }>
                        <Row>
                          <Col span={24}>
                            <Form layout='vertical'>
                              <Row gutter={[10, 0]}>
                                <Col span={24} md={12}>
                                  <Form.Item label='Género'>
                                    <Select>
                                      <Option value={1}>Option 1</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                  <Form.Item label='Entrevistador'>
                                    <Select>
                                      <Option value={1}>Option 1</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Paragraph strong>Lugar</Paragraph>
                                </Col>
                                <Col span={24} md={12}>
                                  <Form.Item label='Comunidad'>
                                    <Select>
                                      <Option value={1}>Option 1</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                  <Form.Item label='PU'>
                                    <Select>
                                      <Option value={1}>Nombre de la PU</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Paragraph strong>Fecha de entrevista</Paragraph>
                                </Col>
                                <Col span={24} md={12}>
                                  <Form.Item label='Desde'>
                                    <Select>
                                      <Option value={1}>Femenino</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                  <Form.Item label='Hasta'>
                                    <Select>
                                      <Option value={1}>Femenino</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                  <Form.Item label='Mostrar último'>
                                    <Radio.Group defaultValue="a">
                                      <Radio.Button value="a">24 horas</Radio.Button>
                                      <Radio.Button value="b">7 día</Radio.Button>
                                      <Radio.Button value="c">1 mes</Radio.Button>
                                      <Radio.Button value="d">3 mes</Radio.Button>
                                      <Radio.Button value="v">1 año</Radio.Button>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Modal>
                    </Col>
                    <Col>
                      <Button type='primary' shape='round' >Crear XML</Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Table dataSource={dataSource} columns={columns} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <Row>
                <Col span={24}>
                  <Alert
                    message="Estos códigos son incorrectos"
                    description="04 de 100 código no han sido sincronizados o no existen en el to-do list"
                    type="error"
                    closable
                  />
                </Col>
                <Col span={24}>
                  <br/>
                  <Paragraph strong>Códigos incorrectos (04)</Paragraph>

                  <Table dataSource={dataSource} columns={columnsDetail} pagination={false} />
                  <br/>
                  <Button type='primary' shape='round' icon={<CopyOutlined />} >Cópiar Códigos</Button>
                </Col>
              </Row>
            </Card>
          </Col>*/}
        </Row>
      </Col>
    </Row>
  );
}

export default CreateXMLSCU;
