// Importaciones necesarias
import { useState, useCallback, useEffect } from 'react';
import useFetch from "../../../../hooks/useFetch";
import { AxiosResponse } from 'axios';

// Definición del hook useCommunityWorker
const useCommunityWorker = () => {
    const { fetchApi, loading } = useFetch();
    const [communityWorkers, setCommunityWorkers] = useState<Array<any>>([]);

    const getCommunityWorkers = useCallback(() => {
        const dataSend = {
            url: `/comms/get_monitoreo`,
        };

        fetchApi(dataSend).then(
            ({ data }: AxiosResponse<{ status: string; data: any; message?: string }>) => {
                if (data.status === 'success') {
                    const dataResponse = data.data;
                    setCommunityWorkers(dataResponse?.content || []);
                } else {
                    console.error(data?.message || 'Error al obtener los trabajadores comunitarios');
                }
            }
        ).catch((error: any) => {
            console.error(error?.message || 'Error en la solicitud');
        });
    }, [fetchApi]);

    // useEffect para llamar a getCommunityWorkers automáticamente cuando el hook se monta
    useEffect(() => {
        if (communityWorkers.length === 0) {
            getCommunityWorkers();
        }
    }, []); // getCommunityWorkers, communityWorkers.length

    return { communityWorkers, loading };
};

export default useCommunityWorker;
