import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography
} from "antd";
import {CheckCircleFilled, ExclamationCircleFilled, FileExcelOutlined, SyncOutlined} from "@ant-design/icons";
import DpaSelected from "../../../../Components/DpaSelected";
import DrawerProjectC2 from "../../components/DrawerProjectC2";
import BarGraphic from "../../components/graficas/BarGraphic";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const GraficasCompromiso2 = () => {
  return(
    <Layout>
      <Content>
        <Row justify='center'>
          <Col span={24} className='p-1'>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Breadcrumb>
                  <Breadcrumb.Item>Compromisos 2</Breadcrumb.Item>
                  <Breadcrumb.Item>Boleta de Reporte Inmediato</Breadcrumb.Item>
                </Breadcrumb>
                <Title level={3}>Monitoreo de Proyectos</Title>
                <Paragraph strong>Plan International llegará a todas las comunidades en donde se encuentran niños y niñas patrocinadas con intervenciones de proyectos cada año y se compromete a monitorear sus resultados a través de nuestro sistema MERL</Paragraph>
              </Col>
              <Col span={24}>
                <Card className='border-radius'>
                  <Row gutter={[10, 30]}>
                    <Col span={24}>
                      <Row justify='space-between'>
                        <Col>
                          badge
                          {/*<Badge dot={(Object.keys(dataFilters)?.length > 0)} >
                            <Button type='primary' ghost icon={ <FileExcelOutlined /> } onClick={() => setOpenModal(true)}>
                              Filtros
                            </Button>
                          </Badge>*/}
                        </Col>
                        <Col>
                          <Row align='middle' gutter={[20, 20]}>
                            <Col>
                              <Tooltip title='volver a cargar la lista de registros.'>
                                {/*<Button icon={<SyncOutlined />} onClick={() => getTabla(0)} />*/}
                              </Tooltip>
                            </Col>
                            <Col>
                              {/*<Button type='primary' loading={downloading} onClick={() => setOpenModalFilterExport(true)} >Exportar Excel</Button>*/}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={24} lg={18}>
                          <BarGraphic title="Categorías" />
                        </Col>
                        <Col span={24} lg={6}>
                          asd
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default GraficasCompromiso2;
