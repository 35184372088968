import {Button, Card, Checkbox, Col, Form, Input, Row} from "antd";
import {IModule, Scope} from "../../models";
import useFetch from "../../../../../hooks/useFetch";
import useMessageUser from "../../../../../hooks/useMessageUser";
import {useEffect, useState} from "react";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import {getScopes} from "../../helperComms";

const FormGroup = ({ setView, dataEdit}: {setView: any, dataEdit: any}) => {

  const { fetchApi, loading } = useFetch();
  const [form] = Form.useForm();
  const { showMessage }  = useMessageUser();
  const [modules, setModules] = useState<Array<IModule>>([]);
  const scopes = getScopes();

  useEffect(() => {
    form.setFieldValue('groName', dataEdit?.groName);
    dataEdit?.groupsScopes?.forEach((item: any) => {
      form.setFieldValue(`scope_${item?.scopes?.scoId}`, true);
    });
  }, [dataEdit]);

  useEffect(() => {
    getModules();
  }, []);
  const getModules = () =>  {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/module`,
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setModules(data.data);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const onFinish = (values: any) => {
    let data: any = {
      groName: values.groName,
      groupsScopes: Object.keys(values)
        .filter(key => key.startsWith('scope_') && values[key])
        .map(key => ({
          scoId: parseInt(key.split('_')[1], 10)
        }))
    };

    if (dataEdit?.groId) {
      data.groId = dataEdit.groId;
    }

    const dataSend: FetchApiInterface = {
      url: `/catalogs/group/save_group`,
      data: data,
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          setView('list');
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  return(
    <div>
      <Card className='border-radius'>
        <Row justify='center'>
          <Col span={24} className='text-right py-2'>
            <Button type='primary' onClick={() => setView('list')}>Regresar</Button>
          </Col>
          <Col span={24} md={20}>
            <Form layout='vertical' form={form} onFinish={onFinish}>
              <Row>
                <Col span={24} md={12} lg={8}>
                  <Form.Item
                    label='Nombre del grupo'
                    name='groName'
                    rules={[{ required: true, message: 'El nombre del grupo es requerido' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {
                  modules.map((module: IModule) => (
                    <Col span={24} key={module.modId}>
                      <p>
                        <strong>{ module.modName }</strong>
                      </p>
                      {
                        (module.modules.length > 0) ? (
                          <Row style={{ paddingLeft: 30 }}>
                            {
                              module.modules.map((subModule: IModule) => (
                                <Col span={24} key={subModule.modId}>
                                  <p>
                                    { subModule.modName }
                                  </p>
                                  <div style={{ paddingLeft: 60 }}>
                                    {
                                      subModule.scopes.map((scope: Scope) => (
                                        <div key={scope.scoId} style={{ marginBottom: 20 }}>
                                          <Form.Item
                                            name={ `scope_${scope.scoId}` }
                                            valuePropName='checked'
                                          >
                                            <Checkbox disabled={!scopes.includes('USUARIOS_GROUP_NEW')} defaultChecked={false}>
                                              { scope.scoName }
                                            </Checkbox>
                                          </Form.Item>
                                        </div>
                                      ))
                                    }
                                  </div>
                                </Col>
                              ))
                            }
                          </Row>
                        ) : (
                          <Row style={{ paddingLeft: 30 }}>
                            <div>
                              {
                                module.scopes.map((scope: Scope) => (
                                  <div key={scope.scoId} style={{ marginBottom: 20 }}>
                                    <Form.Item
                                      name={ `scope_${scope.scoId}` }
                                      valuePropName='checked'
                                    >
                                      <Checkbox disabled={!scopes.includes('USUARIOS_GROUP_NEW')} defaultChecked={false}>
                                        { scope.scoName }
                                      </Checkbox>
                                    </Form.Item>
                                  </div>
                                ))
                              }
                            </div>
                          </Row>
                        )
                      }

                    </Col>
                  ))
                }
                {
                  scopes.includes('USUARIOS_GROUP_NEW') && (
                    <Col span={24} className='py-2'>
                      <Button type='primary' htmlType='submit' loading={loading}>Guardar</Button>
                    </Col>
                  )
                }
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default FormGroup;
