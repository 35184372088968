import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import {Button, Col, Grid, Row, Spin, Typography} from "antd";
import {GlobalCard} from "../Components/GlobalCard";
import {ListLanguage} from "../Components/Language/ListLanguage";
import {useContext, useEffect, useState} from "react";
import {CreateLanguage} from "../Components/Language/CreateLanguage";
import useFetch from "../../hooks/useFetch";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import {LanguageListInterface} from "../../interfaces/LanguageList.interface";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {useTranslation} from "react-i18next";
import useMessageUser from "../../hooks/useMessageUser";
import GlobalContex from "../../Store/GlobalContext";
import {hasAccess} from "../../utils";

const { useBreakpoint } = Grid;

function Language() {

  const { t } = useTranslation();
  const { updateListLanguages: { setUpdateLis } } = useContext(GlobalContex);

  const actions = {
    list: {
      key: "list",
      name: t('menuLanguages'),
      breadcrumb: [
        {
          children: t('menuLanguages')
        }
      ]
    },
    create: {
      key: "create",
      name: t('languageCreateBtn'),
      breadcrumb: [
        {
          children: t('menuLanguages'),
        },
        {
          children: t('languageCreateBtn'),
        }
      ]
    },
  }

  const [language, setLanguage] = useState<any>( actions.list);
  const [edit, setEdit] = useState<LanguageListInterface>();
  const [languages, setLanguages] = useState<Array<LanguageListInterface>>( []);
  const { md } = useBreakpoint();
  const { fetchApi, loading } = useFetch();
  const { showMessage }  = useMessageUser();

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    if (edit?.lanId) {
      changeAction();
    }
  }, [edit]);

  const changeAction = () => {
    const action = (language.key === "list") ? actions.create : actions.list;
    if (action.key === "list") {
      setEdit(undefined);
    }
    setLanguage(action);
  }

  const getLanguages = () => {
    setUpdateLis(true);
    const dataSend: FetchApiInterface = {
      url: '/language/get_language',
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          setLanguages(data.data);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    }).finally(() => {
      setUpdateLis(false);
    });
  }

  return (
    <Row justify='center' className='py-2'>
      <Col span={23}>
        <GlobalCard breadcrumb={language.breadcrumb}>
          <Row justify="space-between" className='mb-2'>
            <Col flex="auto">
              <Typography.Title level={4}>{ language.name }</Typography.Title>
            </Col>
            <Col>
              {
                (language.key === "list") ? (
                  <>
                    {
                      (hasAccess(['ROLE_USER', 'ROLE_GUEST', 'ROLE_COUNTRY_PROCESS'])) && (
                        <Button size="large" type="primary" className="btn-border-radius" onClick={changeAction}>
                          <PlusOutlined />{ (!md)? null : ` ${t('languageCreateBtn')}` }
                        </Button>
                      )
                    }
                  </>
                ) : (
                  <Button size="large" type="dashed" danger className="btn-border-radius" onClick={changeAction}>
                    <ArrowLeftOutlined />{ (!md)? null : ` ${t('return')}` }
                  </Button>
                )
              }
            </Col>
          </Row>
          <Spin spinning={loading}>
            {
              (language.key === "list") && (
                <>
                  {
                    languages.map((lng) => (
                      <ListLanguage key={lng.lanId} lng={lng} getLanguages={getLanguages} setEdit={setEdit}/>
                    ))
                  }
                </>
              )
            }
            {
              (language.key === "create") && <CreateLanguage changeAction={changeAction} getLanguages={getLanguages} edit={edit}/>
            }
          </Spin>
        </GlobalCard>
      </Col>
    </Row>
  );
}

export default Language;
