import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  Select,
  Radio,
  Tooltip, Menu, Checkbox, Dropdown, Avatar, Spin
} from "antd";
import {
  CheckCircleFilled,
  CloudDownloadOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleFilled,
  FileExcelOutlined,
  FileOutlined,
  PlusOutlined, SyncOutlined
} from "@ant-design/icons";
import useFetch from "../../../../../hooks/useFetch";
import useMessageUser from "../../../../../hooks/useMessageUser";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import {IMonitoring} from "../../models";
import {useTranslation} from "react-i18next";
import {getScopes} from "../../helperComms";
import DpaSelected from "../../../../Components/DpaSelected";
import PuOnly from "../../../../Components/PuOnly";

const { Title, Paragraph, Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const colors = ['#f56a00', '#1890ff', '#87d068', '#f56a00', '#fde3cf'];
const FDCLeadersAssignment = () => {
  const { t } = useTranslation();
  const scopes = getScopes();

  const initialColumns = [
    {
      title: t('group'),
      dataIndex: 'cgrName',
      key: 'cgrName',
      visible: true,
    },
    {
      title: t('users'),
      dataIndex: 'users',
      key: 'users',
      visible: true,
      render: (users: any[]) => (
        <Avatar.Group
          maxCount={5}
          size="large"
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
        >
          {users.map((user, index) => (
            <Tooltip key={user.ugrId} title={`${user.user.useName} ${user.user.useLastName}`} placement="top">
              <Avatar style={{ backgroundColor: colors[index % colors.length] }}>
                {user.user.useName.charAt(0)}{user.user.useLastName.charAt(0)}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: t('actions'),
      dataIndex: 'id',
      key: 'id',
      visible: true,
      render: (item: any, row: any) => {
        return(
          <Button
            type='primary'
            onClick={() => setDataRow(row)}
            icon={<EditOutlined />}
            ghost
          />
        );
      }
    },
  ];

  const { key } = useParams();
  const [form] = Form.useForm();
  const [openModalGroup, setOpenModalGroup] = useState(false);
  const [openModalGroupAssign, setOpenModalGroupAssign] = useState(false);
  const { fetchApi, loading } = useFetch();
  const [dataFilters, setDataFilters] = useState<any>({});
  const [dataRow, setDataRow] = useState<any>({});
  const [usersGroup, setUsersGroup] = useState<any>([]);
  const { showMessage }  = useMessageUser();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchText, setSearchText] = useState<string>('');
  const [dataSource, setDataSource] = useState<Array<IMonitoring>>([]);
  const [columns, setColumns] = useState<Array<any>>(initialColumns);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
    total: 0,
  });

  useEffect(() => {
    getTabla();
  }, [dataFilters]);

  useEffect(() => {
    if (!openModalGroupAssign) {
      setDataRow({});
      setUsersGroup([]);
      form.resetFields();
    }
  }, [openModalGroupAssign]);

  useEffect(() => {
    if (dataRow?.cgrId) {
      getUsers();
      // Inicializa selectedUsers con los usuarios que ya están en el grupo
      const existingUsers = dataRow?.users?.map((user: any) => ({
        usuId: user.user.useId,
        ugrLead: user.ugrLead,
      }));
      setSelectedUsers(existingUsers);
      setOpenModalGroupAssign(true);
    }
  }, [dataRow]);

  // Función para manejar el cambio en el estado de visibilidad de las columnas
  const handleVisibleChange = (e: any, columnName: any) => {
    const newColumns = columns.map(column => {
      if (column.key === columnName) {
        return { ...column, visible: e.target.checked };
      }
      return column;
    });
    setColumns(newColumns);
  };

  const visibleColumns = columns.filter(column => column.visible);

  // Menú para el Dropdown con Checkboxes para cada columna
  const menu = (
    <Menu>
      {columns.map(column => (
        <Menu.Item key={column.key}>
          <Checkbox
            checked={column.visible}
            onChange={e => handleVisibleChange(e, column.key)}
          >
            {column.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  // El Dropdown que muestra el menú
  const dropdown = (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button type='primary' shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );

  const handleSearch = (value: string) => {
    if (value) {
      setSearchText(value.trim());
      setDataFilters((prevFilters: any) => ({ ...prevFilters, search: value.trim() }));
    }
  };

  const handleTableChange = (pagination: any) => {
    getTabla(pagination.current, pagination.pageSize);
  };

  const getTabla = (page = 1, size = 20) => {
    let dateSend = {
      ...dataFilters,
    };
    if (dataFilters?.dpas && !Array.isArray(dataFilters.dpas)) {
      dateSend.dpas = [{ dpaId: dataFilters.dpas }];
    }
    const dataSend: FetchApiInterface = {
      url: `/users/group_comms/find_all?page=${page - 1}&size=${size}`,
      data: dateSend,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setDataSource(dataResponse?.content || []);
          setPagination({
            ...pagination,
            current: data?.data?.pageable?.pageNumber + 1,
            total: data?.data?.totalElements,
            pageSize: size,
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const onFinish = (values: any) => {
    const dataSend: FetchApiInterface = {
      url: `/users/group_comms`,
      data: values,
    };
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          setOpenModalGroup(false);
          form.resetFields();
          getTabla();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  };

  const getUsers = (puId: any = null) => {
    const dataSend: FetchApiInterface = {
      url: `/users/get_group_comms_user`,
      data: {
        puId,
      }
    };
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setUsersGroup(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  };

  const handleAssignUsers = () => {
    const users = selectedUsers.map((user: any) => ({
      useId: user.usuId,
      ugrLead: user.ugrLead,
    }));
    const dataSend: FetchApiInterface = {
      url: `/users/group_comms/save_user`,
      data: {
        cgrId: dataRow.cgrId,
        users,
      },
    };
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage('Usuarios asignados exitosamente', 'success');
          setOpenModalGroupAssign(false);
          setSelectedUsers([]);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  };

  const handleUserChange = (checked: boolean, user: any, lead: boolean) => {
    const updatedUsers: any = checked
      ? [
        ...selectedUsers.filter((u: any) => u.usuId !== user.usuId),
        { ...user, ugrLead: lead }
      ]
      : selectedUsers.filter((u: any) => u.usuId !== user.usuId);
    setSelectedUsers(updatedUsers);
  };

  const puIdSelected = (puId: any) => {
    if (puId) {
      getUsers(puId);
    }
  }

  return(
    <Row justify='center'>
      <Col span={24} className='p-1'>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>{ t('user') }</Breadcrumb.Item>
              <Breadcrumb.Item>{ key }</Breadcrumb.Item>
            </Breadcrumb>
            <Title level={3}>{ t('assignFdcLeaders') }</Title>
          </Col>
          <Col span={24}>
            <Card className='border-radius'>
              <Row gutter={[10, 20]}>
                <Col span={24}>
                  <Row justify='end'>
                    <Modal
                      title={ t('createNewGroup') }
                      open={openModalGroup}
                      onCancel={() => setOpenModalGroup(false)}
                      footer={[
                        <Button key="back" onClick={() => setOpenModalGroup(false)}>{ t('btnCancel') }</Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>{ t('btnSave') }</Button>,
                      ]}
                    >
                      <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                          label={ t('groupName')}
                          name="cgrName"
                          rules={[{ required: true, message: `${t('enterGroupName')}` }]}
                        >
                          <Input />
                        </Form.Item>
                      </Form>
                    </Modal>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify='space-between'>
                    {
                      (scopes.includes('USUARIOS_LEAD_NEW')) && (
                        <Col>
                          <Button shape='round' type='primary' icon={<PlusOutlined />} onClick={() => setOpenModalGroup(true)}>{ t('createNewGroup') }</Button>
                        </Col>
                      )
                    }
                    <Col>
                      <Row gutter={[10, 20]}>
                        <Col>
                          <Search placeholder={t('placeholderSearch')} onSearch={handleSearch} allowClear enterButton value={searchText} onChange={(e) => setSearchText(e.target.value) } />
                        </Col>
                        <Col>
                          { dropdown }
                        </Col>
                        <Col>
                          <Tooltip title={t('reloadListTooltip')}>
                            <Button icon={<SyncOutlined />} onClick={() => getTabla(0)} />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Table dataSource={dataSource} columns={visibleColumns} scroll={{ x: 800 }} pagination={pagination} onChange={handleTableChange} rowKey='cgrId' />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Modal
        title={ t('assignUsersToGroups') }
        open={openModalGroupAssign}
        onCancel={() => setOpenModalGroupAssign(false)}
        footer={(scopes.includes('USUARIOS_LEAD_NEW')) ? [
          <Button key="back" onClick={() => setOpenModalGroupAssign(false)}>{ t('btnCancel') }</Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleAssignUsers}>{ t('saveChanges') }</Button>,
        ]: [
          <Button key="back" onClick={() => setOpenModalGroupAssign(false)}>{ t('btnCancel') }</Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Form form={form} layout="vertical">
              <PuOnly form={form} name='puId' isRequired={true} puIdSelected={puIdSelected} />
            </Form>
          </Col>
          {
            (usersGroup.length > 0) && (
              <Col span={24}>
                <Spin spinning={loading}>
                  <Table dataSource={usersGroup} rowKey="usuId" pagination={false}>
                    <Table.Column title={`${t('name')}`} dataIndex="usuName" key="usuName" />
                    <Table.Column
                      title={`${t('inGroup')}`}
                      key="inGroup"
                      render={(text, user: any) => (
                        <Checkbox
                          disabled={!(scopes.includes('USUARIOS_LEAD_NEW'))}
                          onChange={(e) => handleUserChange(e.target.checked, user, false)}
                          checked={selectedUsers.some((u: any) => u.usuId === user.usuId)}
                        />
                      )}
                    />
                    <Table.Column
                      title={`${t('role')}`}
                      key="role"
                      render={(text, user: any) => {
                        const isLeader = selectedUsers.some((u: any) => u.usuId === user.usuId && u.ugrLead);
                        return (
                          <Select
                            defaultValue={isLeader ? "Líder de grupo" : "Facilitador"}
                            onChange={(value) => handleUserChange(true, user, value === "Líder de grupo")}
                            disabled={!selectedUsers.some((u: any) => u.usuId === user.usuId)}
                          >
                            <Option value="Facilitador">{t('facilitator') }</Option>
                            <Option value="Líder de grupo">{ t('groupLeader') }</Option>
                          </Select>
                        );
                      }}
                    />
                  </Table>
                </Spin>
              </Col>
            )
          }
        </Row>
      </Modal>
    </Row>
  );
}

export default FDCLeadersAssignment;
