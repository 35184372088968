import React, {useEffect, useState} from 'react';
import {Form, Checkbox, Select, Input, Tooltip, Card} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {FetchApiInterface} from "../../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../../interfaces/Response.interface";
import useFetch from "../../../../../../hooks/useFetch";
import useMessageUser from "../../../../../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";

const { Option } = Select;
const { TextArea } = Input;

const NutritionComponent = ({ form, handleChange, formState }: { form: any, handleChange: Function, formState: any }) => {

  const { fetchApi, loading } = useFetch();
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();
  const [tmaOption, setTmaOption] = useState([]);

  useEffect(() => {
    getListStatus();
  }, []);

  const getListStatus = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/type-malnutrition`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setTmaOption(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const changeNutEnable = (option: boolean) => {
    handleChange('nutrition', 'nutEnable', option);
    if (!option) {
      form.setFieldsValue({
        nutMalnutrition: false,
        typeMalnutrition: null,
        nutDetail: null,
      });
      handleChange('nutrition', 'nutMalnutrition', false);
      handleChange('nutrition', 'typeMalnutrition', null);
      handleChange('nutrition', 'nutDetail', null);
    }
  }

  return (
    <div>
      <Form.Item name="nutEnable" valuePropName="checked">
        <Checkbox onChange={(e) => changeNutEnable(e?.target?.checked)}>{t('nutrition')}</Checkbox>
      </Form.Item>
      {
        (formState?.nutrition?.nutEnable === true) && (
          <Card>
            <Form.Item name="nutMalnutrition" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('nutrition', 'nutMalnutrition', e?.target?.checked)}>{t('moderateAcuteMalnutrition')}</Checkbox>
            </Form.Item>
            <Form.Item
              label={t('typeOfMalnutrition')}
              name="typeMalnutrition"
              tooltip={{ title: t('malnutritionTypeInfo'), icon: <InfoCircleOutlined /> }}
              rules={[{ required: true, message: t('codeRequired') }]}
            >
              <Select placeholder={t('selectType')} onChange={(e) => handleChange('nutrition', 'typeMalnutrition', e)}>
                {
                  tmaOption.map((option: any) => (
                    <Option key={option.tmaId} value={option.tmaId}>
                      {option.tmaName}
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item label={t('nutritionDetailsLabel')} name="nutDetail" rules={[{ required: true, message: t('codeRequired') }]}>
              <TextArea placeholder={t('enterTaskDescription')} rows={4} onChange={(e) => handleChange('nutrition', 'nutDetail', e.target.value)} />
            </Form.Item>
          </Card>
        )
      }
    </div>
  );
};

export default NutritionComponent;
