import {message} from "antd";
import useFetch from "../useFetch";
import {useState} from "react";

function useExport() {

  const { downloadFile }  = useFetch();

  const [downloading, setDownloading] = useState(false);

  const exportExcel = async (url: string, dataSend: any = {}, fileName: string = '', method: string = 'post') => {
    setDownloading(true);
    try {
      const response = await downloadFile(url, dataSend, method);
      if (response.status !== 200) {
        message.error('Error al descargar el archivo.');
        return;
      }

      // Generamos la fecha en formato corto (DDMMYYYY)
      const today = new Date();
      const formattedDate = `${today.getDate().toString().padStart(2, '0')}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getFullYear()}`;
      const defaultFilename = `export${formattedDate}`;

      // Limpiamos caracteres no alfanuméricos del nombre del archivo
      const sanitizedFilename = `${(fileName || defaultFilename).replace(/[^a-zA-Z0-9]/g, '')}.xlsx`;

      // Creamos un nuevo archivo con el nombre limpio
      const file = new File([response.data], `${sanitizedFilename}`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(new Blob([file]));
      downloadLink.setAttribute('download', sanitizedFilename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink); // Limpieza del enlace
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.error("Error al exportar el archivo", error);
      message.error('Ocurrió un error durante la descarga del archivo.');
    }
  };

  const exportXML = async (url: string, dataSend: any = {}, fileName: string = '', method: string = 'post') => {
    setDownloading(true);
    try {
      const response = await downloadFile(url, dataSend, method);
      if (response.status !== 200) {
        message.error('Error al descargar el archivo.');
        return;
      }

      // Creamos un nombre de archivo basado en la fecha actual (ejemplo: 'nuevahistoria17102024')
      const today = new Date();
      const formattedDate = `${today.getDate().toString().padStart(2, '0')}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getFullYear()}`;
      const defaultFilename = `nuevahistoria${formattedDate}.xml`;

      // Limpiamos cualquier carácter no alfanumérico del nombre de archivo si se proporciona
      const sanitizedFilename = (fileName || defaultFilename).replace(/[^a-zA-Z0-9]/g, '');

      // Creamos un nuevo archivo con el nombre y tipo MIME adecuado para XML
      const file = new File([response.data], `${sanitizedFilename}`, { type: 'text/xml' });
      const downloadLink = document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(new Blob([file], { type: 'text/xml' }));
      downloadLink.setAttribute('download', sanitizedFilename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink); // Limpieza del enlace
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.error("Error al exportar el archivo", error);
      message.error('Ocurrió un error durante la descarga del archivo.');
    }
  };

  const exportZIP = async (url: string, dataSend: any = {}, fileName: string = '', method: string = 'post') => {
    setDownloading(true);
    try {
      const response = await downloadFile(url, dataSend, method);
      if (response.status !== 200) {
        message.error('Error al descargar el archivo.');
        return;
      }

      // Creamos un nombre de archivo basado en la fecha actual (ejemplo: 'scu17102024')
      const today = new Date();
      const formattedDate = `${today.getDate().toString().padStart(2, '0')}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getFullYear()}`;
      const defaultFilename = `${fileName || 'scu'}${formattedDate}.zip`;

      // Limpiamos cualquier carácter no alfanumérico del nombre de archivo si se proporciona
      const sanitizedFilename = (defaultFilename).replace(/[^a-zA-Z0-9]/g, '');

      // Creamos un nuevo archivo con el nombre y tipo MIME adecuado para ZIP
      const file = new File([response.data], `${sanitizedFilename}`, { type: 'application/zip' });
      const downloadLink = document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(new Blob([file], { type: 'application/zip' }));
      downloadLink.setAttribute('download', sanitizedFilename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink); // Limpieza del enlace
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.error("Error al exportar el archivo", error);
      message.error('Ocurrió un error durante la descarga del archivo.');
    }
  };


  return {
    exportExcel,
    exportXML,
    exportZIP,
    downloading,
  };
}

export default useExport;
