import {Button, Card, Col, Form, Row, Spin, Typography} from "antd";
import {GlobalCard} from "../Components/GlobalCard";
import {useContext, useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import Search from "antd/lib/input/Search";
import {PlusOutlined, DeleteOutlined, ArrowLeftOutlined, FilterOutlined, SyncOutlined} from "@ant-design/icons";
import TableCustom from "../Components/TableCustom";
import useProcesDataTable from "../../hooks/useProcesDataTable";
import {TableDataInterface} from "../../interfaces/TableData.interface";
import {dataInitial} from "../../config/dataConfig";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import FormDevice from "../Components/Device/FormDevice";
import {useTranslation} from "react-i18next";
import useMessageUser from "../../hooks/useMessageUser";
import {Space} from "antd/es";
import DpaSelected from "../Components/DpaSelected";
import {hasAccess} from "../../utils";
import GlobalContex from "../../Store/GlobalContext";

const dataConfig = [
  {
    key: 'devId',
    title: 'ID',
    hiddenToColumn: true,
  },
  {
    key: 'devDescription',
    title: 'deviceTableDescription'
  },
  {
    key: 'dpa.dpaFather.dpaName',
    title: 'deviceTableRegion'
  },
  {
    key: 'dpa.dpaName',
    title: 'deviceTableCountry'
  },
  {
    key: 'devUuid',
    title: 'deviceTableMacAddress'
  }
];

const actions = {
  list: {
    key: "list",
    name: "Idiomas",
    breadcrumb: [
      {
        children: "Dispositivos"
      }
    ]
  },
  create: {
    key: "create",
    name: "Crear idioma",
    breadcrumb: [
      {
        children: "Idiomas",
        href: '/',
      },
      {
        children: "Crear idioma"
      }
    ]
  },
}

function Device() {

  const [form] = Form.useForm();
  const { md, lg } = useBreakpoint();
  const { t } = useTranslation();
  const { showMessage }  = useMessageUser();
  const { user: { auth } } = useContext(GlobalContex);

  const [dataRegisters, setDataRegister] = useState<TableDataInterface>(dataInitial);
  const [action, setAction] = useState<any>( {});
  const [edit, setEdit] = useState<any>({});
  const [expanded, setExpanded] = useState<boolean>( true);
  const { fetchApi, loading } = useFetch();
  const { processData } = useProcesDataTable();
  const [filterApply, setFilterApply] = useState<any>({});

  useEffect(() => {
    setFilterApply({});
  }, []);

  useEffect(() => {
    if (action.key === "list") {
      setFilterApply({});
    }
  }, [action]);

  useEffect(() => {
    if (lg) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [lg]);

  useEffect(() => {
    processFilter();
  }, [filterApply]);

  const processFilter = (page = 0) => {
    const values = form.getFieldsValue();

    const dataFilter: any = {};

    if (Boolean(values?.search)) {
      dataFilter.search = values.search;
    }

    if (filterApply?.dpas) {
      dataFilter.dpas = filterApply?.dpas || [];
    } else if (values?.dpa) {
      dataFilter.dpas = [
        {
          dpaId: filterApply.dpa,
        }
      ] || [];
    }

    getRegisters({
      ...dataFilter,
      statusRegister: true,
    }, page);
  }

  const getRegisters = (data: any = {}, page = 0) => {
    setAction(actions.list);
    setEdit({});

    const dataSend: FetchApiInterface = {
      url: `/catalogs/device/get_device?page=${page}`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          const dataResponse = data?.data;
          const dataProcess = processData(dataResponse?.content || [], dataConfig);
          setDataRegister({
            ...dataProcess,
            paginate: {
              size: dataResponse.size,
              totalElements: dataResponse.totalElements,
              totalPages: dataResponse.totalElements,
              pageNumber: dataResponse?.pageable?.pageNumber + 1,
            },
            actions: [
              ...(
                (hasAccess(['ROLE_USER', 'ROLE_GUEST', 'ROLE_COUNTRY_PROCESS']))
                  ? [{
                    action: editRegister,
                    name: t('deviceEditRegister')
                  },
                    {
                      action: deleteRegister,
                      name: 'btnDeleteDevice'
                    }]
                  : []
              ),
            ]
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const deleteRegister = (item: any) => {
    const dataSend: FetchApiInterface = {
      url: `/catalogs/device/delete_device/${item?.devId}`
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          getRegisters();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const editRegister = (item: any) => {
    setEdit(item);
    setAction(actions.create);
  }

  const changeAction = () => {
    const actionItem = (action.key === "list") ? actions.create : actions.list;
    if (actionItem.key === "list") {
      setEdit({});
    }
    setAction(actionItem);
  }

  const clearFilter = () => {
    form.resetFields();
    setFilterApply({});
  }

  const checkClearText = () => {
    const search  = form.getFieldValue('search');
    if (!Boolean(search)) {
      processFilter();
    }
  }

  return(
    <Row justify="center">
      <Col span={24}>
        <Row justify='center' gutter={[20, 20]}>
          {
            (action.key === "list") && (
              <Col span={24} lg={6} xxl={5} className={ (lg) ? 'content-layout' : '' }>
                <Card className="h-100">
                  {
                    (!lg) && (
                      <Row justify="end">
                        <Col>
                          <Button icon={<FilterOutlined />} type='primary' ghost onClick={() => setExpanded(!expanded)}/>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    (expanded) && (
                      <Form
                        form={form}
                        name="filterDeviceForm"
                        layout='vertical'
                      >
                        <Row gutter={[20, 20]}>
                          <Col span={24}>
                            <Typography.Title level={4}>{t('searchTitle')}</Typography.Title>
                            <Form.Item
                              name="search"
                            >
                              <Search allowClear placeholder={t('userSearchPlaceholder')} onSearch={() => processFilter()} onChange={checkClearText}/>
                            </Form.Item>
                          </Col>
                          {
                            (hasAccess(['ROLE_USER', 'ROLE_GUEST', 'ROLE_COUNTRY_ADMIN'])) && (
                              <>
                                <Col span={24}>
                                  <Row justify='space-between' align='middle'>
                                    <Col>
                                      <Typography.Title level={4}>{t('userFilter')}</Typography.Title>
                                    </Col>
                                    <Col>
                                      <Button size='small' type='link' icon={<DeleteOutlined />} onClick={clearFilter}>
                                        <Typography.Text underline>
                                          {t('userCleanFilter')}
                                        </Typography.Text>
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                                {
                                  ((auth?.roles?.includes('ROLE_SYSADMIN') || auth?.roles?.includes('ROLE_GLOBAL_ADMIN') || auth?.roles?.includes('ROLE_REGION_ADMIN'))) && (
                                    <Col span={24}>
                                      <DpaSelected form={form} setFilterApply={setFilterApply} maxLevel={2} filterApply={filterApply} />
                                    </Col>
                                  )
                                }
                              </>
                            )
                          }
                        </Row>
                      </Form>
                    )
                  }
                </Card>
              </Col>
            )
          }
          <Col span={23} lg={(action.key === "list") ? 18 : 23} xxl={(action.key === "list") ? 19: 23} className='py-2' style={{ paddingRight: (lg) ? '30px' : '' }}>
            <GlobalCard breadcrumb={action.breadcrumbs}>
              <Row gutter={[0, 20]} justify="space-between" className='mb-2'>
                <Col flex="auto">
                  <Typography.Title level={4}>
                    {
                      (action.key === "list") ? (
                        <span>
                          {t('deviceTableResults')} ({ dataRegisters.paginate.totalElements })
                        </span>
                      ) : (
                        <span>
                          {t('register')}
                        </span>
                      )
                    }
                  </Typography.Title>
                </Col>
                <Col>
                  {
                    (action.key === "list") ? (
                      <Space>
                        {
                          (hasAccess(['ROLE_USER', 'ROLE_GUEST', 'ROLE_COUNTRY_PROCESS'])) && (
                            <Button size="large" type="primary" className="btn-border-radius" onClick={changeAction}>
                              <PlusOutlined />{ (!md)? null : ` ${t('deviceNewDevice')}` }
                            </Button>
                          )
                        }
                        <Button size="large" type="primary" className="btn-border-radius" onClick={() => getRegisters()}>
                          <SyncOutlined />
                        </Button>
                      </Space>
                    ) : (
                      <Button size="large" type="dashed" danger className="btn-border-radius" onClick={changeAction}>
                        <ArrowLeftOutlined />{ (!md)? null : ` ${t('return')}` }
                      </Button>
                    )
                  }
                </Col>
                <Col span={24}>
                  {
                    (action.key === "list") && (
                      <Spin spinning={loading}>
                        <TableCustom dataTable={dataRegisters} getRegisters={getRegisters} onPageChange={processFilter} />
                      </Spin>
                    )
                  }
                  {
                    (action.key === "create") && (
                      <FormDevice data={edit} getRegisters={getRegisters} />
                    )
                  }
                </Col>
              </Row>
            </GlobalCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Device;
