import {TableDataInterface} from "../interfaces/TableData.interface";

const dataInitial: TableDataInterface = {
  dataSource:[],
  columns: [],
  paginate: {
    totalPages: 0,
    totalElements: 0,
    size: 0
  }
};
export {
  dataInitial
};
