import {type} from "os";
import {useTranslation} from "react-i18next";

function useProcesDataTable() {

  const { t } = useTranslation();

  const processData = (data: Array<any>, fields: Array<any>) => {
    const dataSource: Array<any> = []
    const columns: Array<any> = [];

    // construct to columns
    fields.forEach((item) => {
      if (!item.hiddenToColumn) {
        columns.push({
          ...item,
          dataIndex: item.key,
        });
      }
    });

    data.forEach((value, index) => {
      const objectItem: any = {};
      fields.forEach(filed => {
        if (filed.key.includes('.')) {
          const keysAccess = filed.key.split('.');
          let valueData: any = null;
          keysAccess.forEach((access: string) => {
            if (Boolean(valueData)) {
              valueData = valueData[access];
            } else {
              valueData = value[access];
            }
          });
          objectItem[filed.key] = valueData;
        } else {
          if (filed.key === 'roles') {
            // recorrer el array de roles y formar un string con los nombres de los roles separados por -
            value[filed.key].forEach((rol: any, index: number) => {
              objectItem[filed.key] = (index === 0) ? rol['rolLabel'] : `${objectItem[filed.key]} - ${rol['rolLabel']}`;
            });
          } else if (filed.key === 'updatedAt') {
            objectItem[filed.key] = (value[filed.key] ) ? `${value[filed.key]}`?.substring(0, 10) : '';
          } else if (filed.key === 'statusRegister') {
            objectItem[filed.key] = value[filed.key] ? t('active') : t('inactive');
          } else {
            objectItem[filed.key] = value[filed.key];
          }
        }
      });
      dataSource.push({
        key: `key_${index}`,
        ...objectItem,
      });
    });

    return {
      dataSource,
      columns,
    };
  }

  return { processData };

}

export default useProcesDataTable;
