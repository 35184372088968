import React, { useState } from 'react';
import { Button, Card, Form, Input, DatePicker, List, Modal, Switch, Popconfirm, Row, Col } from 'antd';
import { DeleteOutlined, PlusSquareOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import {useTranslation} from "react-i18next";

interface FollowItem {
  key: string;
  folId?: number;
  folDate: string;
  folVisit: boolean;
  folDetails: string;
}

interface FollowersProps {
  setDataSource: Function;
  dataSource: FollowItem[];
}

const Followers: React.FC<FollowersProps> = ({ dataSource, setDataSource }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingItem, setEditingItem] = useState<FollowItem | null>(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleAdd = () => {
    setEditingItem(null);
    setIsModalVisible(true);
  };

  const handleEdit = (item: FollowItem) => {
    setEditingItem(item);
    setIsModalVisible(true);
    form.setFieldsValue({
      folDate: moment(item.folDate),
      folVisit: item.folVisit,
      folDetails: item.folDetails,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleFinish = (values: any) => {
    const newData: FollowItem = {
      key: editingItem ? editingItem.key : (dataSource.length + 1).toString(),
      folDate: values.folDate.format('YYYY-MM-DD'),
      folVisit: values.folVisit || false,
      folDetails: values.folDetails,
      ...(editingItem && { folId: editingItem.folId }),  // Agrega folId si existe editingItem
    };

    if (editingItem) {
      const newDataSource = dataSource.map((item) => (item.key === editingItem.key ? newData : item));
      setDataSource(newDataSource);
    } else {
      setDataSource([...dataSource, newData]);
    }

    setIsModalVisible(false);
    form.resetFields();
  };

  const handleDelete = (key: number) => {
    const newData = dataSource.filter((item, keyItem) => keyItem !== key);
    setDataSource(newData);
  };

  return (
    <Card style={{ borderRadius: 10 }}>
      <p>
        <strong>{t('trackingHistory')}</strong>
      </p>
      <Button shape="round" type="primary" icon={<PlusSquareOutlined />} onClick={handleAdd}>
        {t('addNewTracking')}
      </Button>
      <List
        itemLayout="vertical"
        dataSource={dataSource}
        renderItem={(item, key) => (
          <List.Item key={key}>
            <Card>
              <Row justify="space-between" align="bottom">
                <Col>
                  <p style={{ marginBottom: '0' }}><strong>{t('date')}:</strong> {item.folDate}</p>
                  <p style={{ marginBottom: '0' }}><strong>{t('visit')}:</strong> {item.folVisit ? t('yes') : t('no')}</p>
                  <p style={{ marginBottom: '0' }}><strong>{t('details')}:</strong> {item.folDetails}</p>
                </Col>
                <Col>
                  <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(item)}>{t('edit')}</Button>
                  <Popconfirm title={t('confirmDelete')} onConfirm={() => handleDelete(key)}>
                    <Button type="link" icon={<DeleteOutlined />} danger>{t('delete')}</Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
      />
      <Modal
        title={editingItem ? t('editTracking') : t('addNewTracking')}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item
            name="folDate"
            label={t('date')}
            rules={[{ required: true, message: t('selectDate') }]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
          <Form.Item
            name="folVisit"
            label={t('visit')}
            valuePropName="checked"
          >
            <Switch defaultChecked={false} checkedChildren={t('yes')} unCheckedChildren={t('no')} />
          </Form.Item>
          <Form.Item
            name="folDetails"
            label={t('details')}
            rules={[{ required: true, message: t('enterDetails') }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingItem ? t('save') : t('add')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default Followers;
