import { ReactNode, useEffect, useState } from "react";
import { Col, Layout, Row, Spin } from "antd";
import HeaderLayout from "./components/HeaderLayout";
import { Content } from "antd/es/layout/layout";
import { closeSession } from "../config/interceptor";

let timeoutID: number | undefined;

function LayoutPrivate({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(true); // Estado para manejar el loader

  useEffect(() => {
    // Inicia el loader por 500 ms
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
    }, 500); // 500 ms

    setup();

    // Limpiar el timeout cuando se desmonte el componente
    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  function setup() {
    window.addEventListener("mousemove", resetTimer, false);
    window.addEventListener("mousedown", resetTimer, false);
    window.addEventListener("keypress", resetTimer, false);
    window.addEventListener("DOMMouseScroll", resetTimer, false);
    window.addEventListener("mousewheel", resetTimer, false);
    window.addEventListener("touchmove", resetTimer, false);
    window.addEventListener("MSPointerMove", resetTimer, false);

    startTimer();
  }

  function startTimer() {
    timeoutID = window.setTimeout(goInactive, 60000 * 30);
  }

  function resetTimer() {
    window.clearTimeout(timeoutID);

    goActive();
  }

  function goInactive() {
    closeSession();
  }

  function goActive() {
    startTimer();
  }

  return (
    <Layout>
      <Content>
        <main className="private-layout">
          <div className="header-layout">
            <Row justify="center" className="h-100">
              <Col span={23} className="h-100">
                <HeaderLayout />
              </Col>
            </Row>
          </div>

          {/* Mostrar loader por 500 ms y luego el contenido */}
          <div className="content-layout bg-secondary">
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
              </div>
            ) : (
              children
            )}
          </div>
        </main>
      </Content>
    </Layout>
  );
}

export default LayoutPrivate;
