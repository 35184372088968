import React, { useEffect, useState, useContext } from 'react';
import {Button, Form, Select, Spin, Typography} from 'antd';
import { useTranslation } from 'react-i18next';
import useFetch from "../../hooks/useFetch";
import GlobalContext from "../../Store/GlobalContext";
import useMessageUser from "../../hooks/useMessageUser";

const { Option } = Select;
const { Text } = Typography;

const DpaOption = ({ activeMultiple = false, dpaId = null, maxLevel = null, setEndLevel = () => {},setFilterApply, filterApply, form }: any) => {
  const { t } = useTranslation();
  const { user: { auth } } = useContext(GlobalContext);
  const { fetchApi, loading } = useFetch();
  const { showMessage } = useMessageUser();
  const [dpaList, setDpaList] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [multiple, setMultiple] = useState(false);
  const [dpaIds, setDpaIds] = useState<Array<any>>([]);

  useEffect(() => {
    form.setFieldValue('dpa', null);
    if (dpaId || (auth?.user?.dpa && !dpaId)) {
      // Iniciar desde el nivel dado por dpaId o desde la sesión del usuario
      getRegions(dpaId || auth?.user?.dpa?.dpaId);
    } else {
      // Iniciar desde el nivel más alto
      getRegions();
    }
    if (maxLevel === 1 && activeMultiple) {
      form.setFieldValue('dpa', []);
      setMultiple(true);
    }
  }, []);

  const getRegions = (dpaId = null) => {
    console.log('llama a regiones', currentLevel, maxLevel);
    if ((dpaId !== null && typeof dpaId === 'object') || (currentLevel >= maxLevel && maxLevel != null)) {
      if (activeMultiple) {
        console.log('ingresa aca al multiple');
        setMultiple(true);
      }
      return;
    }
    let data = {};
    if (dpaId) {
      data = { dpaFatherId: dpaId, statusRegister: true };
    } else {
      data = { dpaType: "Region", statusRegister: true };
    }

    fetchApi({ url: '/catalogs/dpa/get_dpa', data }).then(
      ({ data }) => {
        const dataToList = data?.data || [];
        if (dataToList.length === 0) {
          // No más niveles
          if (maxLevel === null || (activeMultiple && currentLevel === maxLevel)) {
            form.setFieldValue('dpa', []);
            setMultiple(true);
          }
        } else {
          // Buscamos el DPA en la lista
          const dpa: any = dpaList.find((value: any) => (value?.dpaId === dpaId));
          if (dpa?.dpaId) {
            setDpaIds([...dpaIds, { ...dpa }]);
          }
          setDpaList(dataToList);
        }

        setCurrentLevel(currentLevel + 1);
      }
    ).catch((reason) => {
      showMessage(reason?.message, 'error');
    });
  };

  const backDpa = (dpa: any) => {
    const newDpaIds = dpaIds.slice(0, dpaIds.indexOf(dpa));
    setDpaIds(newDpaIds);
    getRegions(newDpaIds[newDpaIds.length - 1]?.dpaId);
    form.setFieldValue('dpa', null);
    setMultiple(false);
  };

  return (
    <>
      <div>
        {/* Botones para regresar a un nivel anterior */}
        <p>
          {
            dpaIds.map((dpa, index) => (
              <Button
                style={{ padding: 0 }}
                type='link'
                key={index}
                disabled={((dpaIds.length - 1) === index)}
                onClick={() => backDpa(dpa)}
              >
                <Text
                  strong={((dpaIds.length - 1) === index)}
                  style={{ color: `${((dpaIds.length - 1) === index) ? '#0072CE' : 'black'}` }}
                >
                  /{ dpa?.dpaName }
                </Text>
              </Button>
            ))
          }
        </p>

        {/* El resto del código del componente */}
      </div>
      <Spin spinning={loading}>
        <Form.Item label={t('dpaLabelName')} name="dpa">
          <Select
            placeholder={t('dpaPlaceholderName')}
            mode={multiple ? 'multiple' : undefined}
            onChange={(e) => getRegions(e)}
          >
            {dpaList.map((item: any) => (
              <Option key={item.dpaId} value={item.dpaId}>
                {item.dpaName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Spin>
    </>
  );
};

export default DpaOption;
