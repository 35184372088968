import {useState} from "react";
import {Button, Grid, message, Upload, UploadProps} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {FormInstance} from "antd/lib/form/hooks/useForm";

const { useBreakpoint } = Grid;

function ButtonUpload({ name, fieldName, form, fileType }: { name: string, fieldName: string, fileType: string, form?: FormInstance<any> }) {

  const { md } = useBreakpoint();
  const [fileList, setFileList] = useState<Array<any>>([]);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      const isEXCEL = file.type === fileType;
      if (!isEXCEL) {
        message.error(`El ${file.name} no es un archivo vàlido.`);
        return;
      }
      setFileList([file]);
      if (form) {
        form.setFieldValue(fieldName, file);
      }
      return false;
    },
    fileList,
  };

  return (
    <>
      <Upload {...props} className={`${ (!md) ? 'upload-w-full' : 'min-w-200' }`}>
        <Button className={`btn-border-radius ${ (!md) ? 'w-100' : 'w-100' }`}>
          { name } <UploadOutlined />
        </Button>
      </Upload>
    </>
  );
}

export default ButtonUpload;
