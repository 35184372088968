export const getScopes = () => {
    const scopes = localStorage.getItem('scopes');
    return scopes ? JSON.parse(scopes) : [];
};

export const getUrlMenu = () => {
    return [
        {
            url:'/importaciones',
            scopes: ['IMP_ACTIVE', 'IMP_INACTIVE', 'IMP_TODO', 'IMP_CHILD']
        },
        {
            url:'/compromisos/compromiso_2',
            scopes: ['COMP_2_LISTAR']
        },
        {
            url:'/compromisos/compromiso_5',
            scopes: ['COMP_5_LISTAR']
        },
        {
            url:'/compromisos/compromiso_6',
            scopes: ['COMP_6_LISTAR']
        },
        {
            url:'/scuapp/monitoring',
            scopes: ['MON_LISTAR']
        },
        {
            url:'/scuapp/create_agenda',
            scopes: ['AGENDA_LISTAR']
        },
        {
            url:'/scuapp/create_agenda_new_stories',
            scopes: ['HISTORIAS_LISTAR']
        },
        {
            url:'/scuapp/create_XML_SCU',
            scopes: ['XML_SCU_LISTAR']
        },
        {
            url:'/scuapp/createXML_new_stories',
            scopes: ['XML_HISTORIAS_LISTAR']
        },
        {
            url:'/users/user_groups',
            scopes: ['USUARIOS_GROUP_LISTAR']
        },
        {
            url:'/users/FDC_leaders_assignment',
            scopes: ['USUARIOS_LEAD_LISTAR']
        },
    ];
}

export const getScopesByRoute = () => {
    const scopes = getScopes();
    const urlMenu = getUrlMenu();
    return urlMenu.reduce((acc: any, item) => {
        const hasMatchingScope = item.scopes.some(scope => scopes.includes(scope));
        if (hasMatchingScope) {
            acc.push(item?.url);
        }
        return acc;
    }, []);
}

