import {useState} from "react";
import useFetch from "./useFetch";
import {FetchApiInterface} from "../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../interfaces/Response.interface";
import {TranslationInterface} from "../interfaces/LanguageList.interface";
import useKeyValueTranslate from "./useKeyValueTranslate";
import {useTranslation} from "react-i18next";
import useMessageUser from "./useMessageUser";

function useSetLanguage() {
  const [ loadLanguage, setLoadLanguage ] = useState(false);
  const { fetchApi } = useFetch();
  const { showMessage }  = useMessageUser();

  const { keyValueTranslate } = useKeyValueTranslate();
  const { i18n } = useTranslation();

  const getLanguage = (language: string) => {
    // const hideMessage = message.loading('Cambiando idioma...', 0);
    setLoadLanguage(true);
    const dataSend: FetchApiInterface = {
      url: '/language/field',
      data: {
        type: "web",
        language
      }
    }

    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {

          const theData: TranslationInterface = data.data;

          const fields    = keyValueTranslate(theData?.fields);
          const messages  = keyValueTranslate(theData?.messages);

          i18n.addResources(language, 'translation', { ...fields, ...messages });
          i18n.changeLanguage(language);
          // hideMessage();
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    }).finally(() => setLoadLanguage(false));
  }

  return { getLanguage, loadLanguage };
}

export default useSetLanguage;
