import {ResponsiveBar} from "@nivo/bar";

const data = [
  {
    "country": "AD",
    "hot dog": 19,
    "hot dogColor": "hsl(182, 70%, 50%)",
    "burger": 28,
    "burgerColor": "hsl(238, 70%, 50%)",
    "sandwich": 195,
    "sandwichColor": "hsl(25, 70%, 50%)",
    "kebab": 35,
    "kebabColor": "hsl(9, 70%, 50%)",
    "fries": 55,
    "friesColor": "hsl(238, 70%, 50%)",
    "donut": 197,
    "donutColor": "hsl(105, 70%, 50%)"
  },
  {
    "country": "AE",
    "hot dog": 131,
    "hot dogColor": "hsl(47, 70%, 50%)",
    "burger": 194,
    "burgerColor": "hsl(94, 70%, 50%)",
    "sandwich": 74,
    "sandwichColor": "hsl(61, 70%, 50%)",
    "kebab": 186,
    "kebabColor": "hsl(139, 70%, 50%)",
    "fries": 200,
    "friesColor": "hsl(76, 70%, 50%)",
    "donut": 29,
    "donutColor": "hsl(57, 70%, 50%)"
  },
  {
    "country": "AF",
    "hot dog": 180,
    "hot dogColor": "hsl(12, 70%, 50%)",
    "burger": 78,
    "burgerColor": "hsl(264, 70%, 50%)",
    "sandwich": 71,
    "sandwichColor": "hsl(35, 70%, 50%)",
    "kebab": 188,
    "kebabColor": "hsl(202, 70%, 50%)",
    "fries": 18,
    "friesColor": "hsl(293, 70%, 50%)",
    "donut": 200,
    "donutColor": "hsl(6, 70%, 50%)"
  },
];

export interface IBarGraphic {
  title: string;
}

const BarGraphic = (config: IBarGraphic) => {
  const { title } = config;
    return (
        <div>
            <h1 className="title-graph">{ title }</h1>
          <div style={{ width: '100%', height: '700px' }}>
            <ResponsiveBar
              data={data}
              keys={[
                'hot dog',
                'burger',
                'sandwich',
                'kebab',
                'fries',
                'donut'
              ]}
              indexBy="country"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={{ scheme: 'nivo' }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
                }
              ]}
              fill={[
                {
                  match: {
                    id: 'fries'
                  },
                  id: 'dots'
                },
                {
                  match: {
                    id: 'sandwich'
                  },
                  id: 'lines'
                }
              ]}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1.2
                  ]
                ]
              }}
              axisTop={null}
              axisRight={null}
              labelSkipWidth={9}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1.6
                  ]
                ]
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
            />
          </div>
        </div>
    );
}

export default BarGraphic;
